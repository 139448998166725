import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import SiteMetadata from "../componets/SiteMetadata/SiteMetadata"
import Header from "../componets/Header/Header"
import Footer from "../componets/Footer/Footer"
import Cgu from "../componets/Cgu/Cgu"
import "./globals.css"
import { graphql } from "gatsby"

export const query = graphql`
  query {
    strapiCgu {
      prelude_title
      prelude_content
      definition_title
      definition_content
      presentation_title
      presentation_content
      acceptance_title
      acceptance_content
      access_title
      access_subtitle
      access_content
      protection_title
      protection_content
      property_title
      property_content
      engagement_title
      engagement_subtitle1
      engagement_content1
      engagement_subtitle2
      engagement_content2
      engagement_subtitle3
      engagement_content3
      data_title
      data_content
      responsability_title
      responsability_content
      force_title
      force_content
      evolution_title
      evolution_content
      duration_title
      duration_content
      applicable_right_title
      applicable_right_content
      notice_title
      notice_content
    }
    logo: file(
      absolutePath: {
        regex: "/images/logo_seformer-f59ecb37be2dc067b743d7ee0a113952.webp/"
      }
    ) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (props) => {
  const { data } = props
  const infos = data.strapiCgu

  return (
    <div>
      <SiteMetadata />
      <Header {...props} logo={data.logo} />
      <Cgu infos={infos} />
      <Footer />
    </div>
  )
}

import React from 'react';
import images from '../../assets/images';
import {Link} from "gatsby";
import './Cgu.css'
import {stripTags} from "../../services/helpers";

var md = require('markdown-it')({
    html: true,
    xhtmlOut: true,
    breaks: true,
});

function Cgu(props) {
    const {infos} = props;
    return (
        <>
            <section className="NavsingleFormation">
                <div className='container container-seformer'>
                    <ul className="breadcrumb-single-formation">
                        <li><Link to="/">Accueil</Link></li>
                        <li><img src={images.breadcrumbsSeparator} alt='breadcrumbs separator'/></li>
                        <li className="active">Conditions Générales d’Utilisation</li>
                    </ul>
                </div>
            </section>
            <div className="pagecgv pt-2 pb-5">
                <div className="titlepage-interne">
                    <div className='container container-seformer'>
                        <div className="d-flex justify-content-center">
                            <h2 className="text-center title-section mx-auto my-3">CONDITIONS GENERALES
                                D'UTILISATION{/* <span>Seformer.re</span>*/}</h2>
                        </div>
                    </div>
                </div>
                <div className='container container-seformer'>
                    <div className="ml-120">
                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.prelude_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.prelude_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>

                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.definition_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.definition_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.presentation_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.presentation_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.acceptance_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.acceptance_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.access_title}</h3>
                            <h4 className="h4cgv">{infos.access_subtitle}</h4>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.access_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.protection_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.protection_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>

                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.property_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.property_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>

                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.engagement_title}</h3>
                            <h4 className="h4cgv">{infos.engagement_subtitle1}</h4>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.engagement_content1), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                            <h4 className="h4cgv">{infos.engagement_subtitle2}</h4>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.engagement_content2), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>

                        <div className="my-5 section-cgv">
                            <h4 className="h4cgv">{infos.engagement_subtitle3}</h4>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.engagement_content3), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>

                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.data_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.data_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>

                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.responsability_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.responsability_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>

                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.force_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.force_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>

                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.evolution_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.evolution_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>

                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.duration_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.duration_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>

                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.applicable_right_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.applicable_right_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>

                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.notice_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.notice_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Cgu;